<template>
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub find">
			<!-- contact -->
			<section class="content" v-if="mmb0302.length > 0">
				<div class="centered centered--large" v-if="mmb0302.length === 1">
					<div class="centered__wrap">
						<div class="textbox">
							<h3 class="textbox__title textbox__title--large">
								{{t('10225')}}
							</h3>
							<!-- [D] 일반 회원가입 일 때 표시 -->
							<template v-if="mmb0302[0].sns_join_flag === undefined || (mmb0302[0].sns_join_flag === '') ">
								<p class="textbox__description">
									{{t('10226')}}
								</p>
								<div class="complete__email">
									{{mmb0302[0].mem_id}}
								</div>
							</template>

							<!-- [D] SNS 회원가입일 때 표시 -->
							<p class="textbox__description" v-else>
								{{snsJoinFlagMap[mmb0302[0].sns_join_flag]}} {{t('10227')}} <br>
								{{snsJoinFlagMap[mmb0302[0].sns_join_flag]}} {{t('10228')}}
							</p>
						</div>
					</div>
				</div>
				
				<div class="row" v-else-if="mmb0302.length > 1">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10225')}}
							</h2>
							<div class="inner__text">
								{{t('10226')}}
							</div>
						</div>
						<div class="inner__wrap">
							<div class="dormant__auth" v-for="member_id in mmb0302" :key="member_id.mem_saatus">
								<label>
									<div class="radio">
										<input type="radio" class="rad blind" name="isDormant" v-model="radioValues" :value="member_id.mem_id">
										<span class="radio__bg"></span>
										<span class="dormant__authtype">
											{{member_id.mem_id}}
										</span>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</section>
			<TheNavigation></TheNavigation>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder" v-if="mmb0302.length === 1">
		<div class="btns btns--centered" style="margin-bottom:-10px;padding-bottom:0" v-if="mmb0302[0].sns_join_flag === null || mmb0302[0].sns_join_flag === ''">
			<button type="button" class="btn btn-primary purple" @click="goFindPw">{{t('10229')}}</button>
		</div>
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm" @click="goLogin">{{t('10230')}}</button>
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder" v-if="mmb0302.length > 1">
		<div class="btns btns--centered" style="margin-bottom:-10px;padding-bottom:0">
			<button type="button" class="btn btn-primary purple" @click="goFindPw">{{t('10229')}}</button>
		</div>
		<div class="btns btns--centered">
			<button type="button" class="btn btn-confirm" @click="goLogin">{{t('10230')}}</button>
		</div>
	</div>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
import { onMounted,ref} from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 
export default {
	setup() {
		const store = useStore();
		const router = useRouter();
		const radioValues = ref();
		const snsJoinFlagMap = {
			"01": "네이버",
			"02": "카카오",
			"03": "애플",
		}
		const { t }= useI18n() //번역필수 모듈
		onMounted(() => {
			radioValues.value = radioValues
			store.dispatch('mmb03/fetchMmb0302');
		})
		// const maskEmail = (email) => {
		// 	let split = email.split('@')
		// 	return split[0].slice(0, -3) + "xxx" + "@" + split[1]
		// }
		return {
			mmb0302: computed(() => store.state.mmb03.mmb0302),
			goLogin: () => {
				router.push("/layers/login");
			},
			goFindPw: () => {
				router.push("/member/find/password/step1");
			},
			//maskEmail,
			snsJoinFlagMap,
			radioValues,
			t,
			i18n,
		}
	},
}
</script>